import { FC, useMemo } from 'react';

import { Card } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import CellContainer from '@/components/ui/CellContainer';
import CellTitle from '@/components/ui/CellTitle';
import ResourceLink from '@/components/ui/ResourceLink';
import { useIntegrationType } from '@/hooks/api/useIntegrationType';
import { PartnerMilestoneType, useRecordMilestoneOnLoad } from '@/hooks/api/useMilestones';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { IntegrationType } from '@/types/store/reducers';

import LinkIcon from 'icons/link.svg';
import DocumentIcon from 'icons/tos.svg';
import VideoIcon from 'icons/video.svg';

import { StyledCollapsibleSection } from './styles';

/* eslint-disable max-len */
const Resources: FC = () => {
	const partner = useDefinedPartner();
	const { data: integrationType } = useIntegrationType(partner.id);
	useRecordMilestoneOnLoad(partner.id, PartnerMilestoneType.RESOURCE_VIEWED);

	const adminChecklistUrl = useMemo(() => {
		if (!integrationType) {
			return null;
		}
		if (integrationType === IntegrationType.ELIGIBILITY_FILE) {
			return 'https://info.calm.com/rs/541-LYF-023/images/Calm_Admin_Checklist_EF.pdf';
		}
		if (integrationType === IntegrationType.SSO) {
			return 'https://info.calm.com/rs/541-LYF-023/images/Calm_Admin_Checklist_SSO.pdf';
		}
		return null;
	}, [integrationType]);

	const topRecommendationResource = (
		<ResourceLink
			href="https://info.calm.com/rs/541-LYF-023/images/Calm%27s_Top_Recommendations.pdf"
			Icon={DocumentIcon}
			openInNewTab
		>
			Calm’s Top Recommendations Resource
		</ResourceLink>
	);

	return (
		<CellContainer>
			<InitialPageAnalytics />
			<Card role="region" aria-labelledby="getting-started-heading">
				<CellTitle id="getting-started-heading">Getting started with Calm</CellTitle>
				<StyledCollapsibleSection headerText="Calm App Overview">
					<ResourceLink href="https://www.youtube.com/watch?v=AOwB2xYM0nI" Icon={VideoIcon} openInNewTab>
						Calm Intro Video
					</ResourceLink>
					<ResourceLink
						href="https://app.frame.io/presentations/93ee138f-d901-4544-a49a-27c92925eb30"
						Icon={VideoIcon}
						openInNewTab
					>
						Calm App Webinar Recording
					</ResourceLink>
					<ResourceLink
						href="https://docs.google.com/presentation/d/1Grt258etQ-9C1c6L-xRCybYVAh7_JtlvqV1vxEJrFQk/edit#slide=id.g20139a343cc_0_61"
						Icon={LinkIcon}
						openInNewTab
					>
						Calm App Webinar Flyers
					</ResourceLink>
					<ResourceLink href="https://business.calm.com/calm-app-webinars" Icon={LinkIcon} openInNewTab>
						Calm App Webinars for Employees
					</ResourceLink>
				</StyledCollapsibleSection>
				<StyledCollapsibleSection headerText="Admin Resources">
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Business_Partner_Portal_Administrator_Guide.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Partner Portal Admin Guide
					</ResourceLink>
					<ResourceLink
						href="https://calm.zoom.us/rec/play/1oRcWcYYe6jA_rpDsn96kSsapPCBNpTCnlvp4HnVgYMblMm4QfpKK9y-Nqs2KNY8vANP1qcLfEpHnz05.J1l1nRPd6fDHuey7?canPlayFromShare=true&from=share_recording_detail&continueMode=true&componentName=rec-play&originRequestUrl=https%3A%2F%2Fcalm.zoom.us%2Frec%2Fshare%2F3B_nsgj-Y0o11qTXV_DfcFF5tLv_fYkKuhOFgdCyZOgdQiDJXNrEgh644a7RT29C.1IzAtioy_VIyDvE_"
						Icon={VideoIcon}
						openInNewTab
					>
						Partner Portal Video Overview
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/WR-Subscription-Partner-Newsletter.html"
						Icon={LinkIcon}
						openInNewTab
					>
						Monthly Partner Newsletter
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Open_Enrollment_Resources.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Open Enrollment Resources
					</ResourceLink>
					<ResourceLink
						href="https://docs.google.com/presentation/d/181NjmkO1Wr5iSS2vjjKHUMs1ViywXbXiWAlQD-JfDbs/edit#slide=id.g1dd5a7ee2c1_0_0"
						Icon={DocumentIcon}
						openInNewTab
					>
						Open Enrollment One-Pager
					</ResourceLink>
				</StyledCollapsibleSection>
				<StyledCollapsibleSection headerText="Launch Resources">
					{adminChecklistUrl ? (
						<ResourceLink Icon={DocumentIcon} href={adminChecklistUrl} openInNewTab>
							Admin Checklist
						</ResourceLink>
					) : null}
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1r4hZSNnNNdoG6oR3pv1EiL0k3jhZv8SuYjDkgJ4I40I/edit#slide=id.gb6a85534c2_0_0"
						openInNewTab
					>
						Calm Benefit Announcement Flyer
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1E-HpnbsU5tZcVT-3spLX_UlC8iOy9asqoLdaDNmnkZM/edit#slide=id.g7fbbbd6b55_0_0"
						openInNewTab
					>
						Calm Benefit Overview Slides (extended)
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1aM0LIxWrjLyMdZD_QLE-4SMZ9P0ucIfQxC5lYi2rgqU/edit#slide=id.g7fbbbd6b55_0_0"
						openInNewTab
					>
						Calm Benefit Overview Slides (condensed)
					</ResourceLink>
					<ResourceLink
						Icon={LinkIcon}
						href="https://docs.google.com/document/d/1sO-w2LM-Q6MGZ3Gozh4OiQuuCACdRYG6VZx29KlgEyk/edit"
						openInNewTab
					>
						Calm Benefit Employee Sign Up Flow
					</ResourceLink>
				</StyledCollapsibleSection>
			</Card>
			<Card role="region" aria-labelledby="getting-the-most-heading">
				<CellTitle id="getting-the-most-heading">Getting the most out of Calm</CellTitle>
				<ResourceLink
					href="https://business.calm.com/elevating-calm-communications-for-2024/"
					Icon={DocumentIcon}
					openInNewTab
				>
					2024 12-Month Engagement Guide for Calm Communications
				</ResourceLink>
				<StyledCollapsibleSection headerText="Engagement Strategies">
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/calm-resources-for-widespread-challenges/"
						Icon={LinkIcon}
						openInNewTab
					>
						Calm Resources for Widespread Challenges
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm Engagement Strategy_Self-Care Bingo.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Self-Care Bingo Challenge
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm-Business-Stress-Burnout-Tipsheet-c.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Stress & Burnout Tip Sheet
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_For_Work_Flyer.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Calm for Work Engagement Flyer
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_For_Families_Flyer.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Calm for Families Engagement Flyer
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/focusing-with-music/"
						Icon={DocumentIcon}
						openInNewTab
					>
						Music For Focus Engagement Strategy
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/moments-that-matter/"
						Icon={LinkIcon}
						openInNewTab
					>
						Moments that Matter Engagement Strategy
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/supporting-your-ergs/"
						Icon={LinkIcon}
						openInNewTab
					>
						Supporting Your ERGs Engagement Strategy
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Content_For_ERGs.pdf?version=0"
						Icon={DocumentIcon}
						openInNewTab
					>
						Calm Content for ERGs Guide
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm Engagement Strategy_The Daily Jay_Communication Templates.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Daily Jay Engagement Strategy
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Engagement_Strategy_The_Daily_Move_Communication_Templates.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Daily Move Engagement Strategy
					</ResourceLink>
					{topRecommendationResource}
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Your_Guide_to_Engaging_Employees_Year-Round_with_Calm.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Supplementary Themed Engagement Strategies
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/parental-leave/"
						Icon={DocumentIcon}
						openInNewTab
					>
						Parental Leave Email Template
					</ResourceLink>
					<ResourceLink
						href="https://docs.google.com/presentation/d/1ct483dRbdD5IyltzOSSWn8haI5_pVjyFPXlEBO_G6t4/edit#slide=id.g18946e9bd4d_0_61"
						Icon={DocumentIcon}
						openInNewTab
					>
						Maternity Leave Flyers
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm-Mindful-Manager-Checklist.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Mindful Manager Checklist
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/calm-travel/"
						Icon={LinkIcon}
						openInNewTab
					>
						Calm for Travel Engagement Strategy
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/mindfulness-on-the-go/"
						Icon={LinkIcon}
						openInNewTab
					>
						Mindfulness on the go Engagement Strategy
					</ResourceLink>
					<ResourceLink
						href="https://docs.google.com/presentation/d/1jfMY5xblBCmU-ra_chJ3OL97PRlm6RH-hNmJPrE369A/edit#slide=id.p"
						Icon={LinkIcon}
						openInNewTab
					>
						Giving the Gift of Calm Holiday Flyers
					</ResourceLink>
					<ResourceLink
						href="https://docs.google.com/presentation/d/1SdV-7Jr3YwkNPFDJBEg-_0mgrl96cdNH4OGR13apJEU/edit?usp=sharing?utm_source=marketo&utm_medium=email&utm_campaign=23_11_december_partnernewsletter"
						Icon={LinkIcon}
						openInNewTab
					>
						Calm for the Holidays Content Flyer
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/calm-for-the-holidays/"
						Icon={LinkIcon}
						openInNewTab
					>
						Calm for the Holidays Engagement Strategy
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/2023-engagement-guide-for-employee-communications/"
						Icon={LinkIcon}
						openInNewTab
					>
						2023 12-Month Engagement Guide for Employee Communications
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/world-mental-health-day-2024/"
						Icon={LinkIcon}
						openInNewTab
					>
						World Mental Health Day Engagement Strategy
					</ResourceLink>
				</StyledCollapsibleSection>

				<StyledCollapsibleSection headerText="Cultural Moment Resources">
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/black-history-month/"
						Icon={LinkIcon}
						openInNewTab
					>
						Black History Month Engagement Strategy
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/womens-history-month"
						Icon={LinkIcon}
						openInNewTab
					>
						Women&apos;s History Month Engagement Strategy
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/national-hispanic-and-latinx-heritage-month"
						Icon={LinkIcon}
						openInNewTab
					>
						Hispanic Heritage Month Engagement Strategy (English)
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/es/engagement-strategies/mes-nacional-de-la-herencia-hispana-y-latina"
						Icon={LinkIcon}
						openInNewTab
					>
						Hispanic Heritage Month Engagement Strategy (Spanish)
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/asian-american-and-pacific-islander-aapi-heritage-month/"
						Icon={LinkIcon}
						openInNewTab
					>
						Asian American and Pacific Islander (AAPI) Heritage Month
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/pride-month/"
						Icon={LinkIcon}
						openInNewTab
					>
						Pride Month Engagement Strategy
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Business_Men%27s_Mental_Health_Month_Communications_Template.docx?version=0"
						Icon={LinkIcon}
						openInNewTab
					>
						Men’s Mental Health Month Communication Template
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/bipoc-mental-health-month/"
						Icon={LinkIcon}
						openInNewTab
					>
						BIPOC Mental Health Month Engagement Strategy
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/engagement-strategies/national-native-american-heritage-month/"
						Icon={LinkIcon}
						openInNewTab
					>
						National Native American Heritage Month Engagement Strategy
					</ResourceLink>
				</StyledCollapsibleSection>
				<ResourceLink
					href="https://info.calm.com/rs/541-LYF-023/images/Virtual_Session_Hosting%20Guide_Calm_Partners.pdf"
					Icon={DocumentIcon}
					openInNewTab
				>
					Virtual Session Hosting Guide
				</ResourceLink>
				<ResourceLink href="https://www.calm.com/blog/calm-calendars" Icon={LinkIcon} openInNewTab>
					Monthly Calendars
				</ResourceLink>
				<StyledCollapsibleSection headerText="Calm Business for Dependents">
					<ResourceLink
						href="https://business.calm.com/how-to-invite-dependents-to-utilize-your-calm-business-benefit/"
						Icon={LinkIcon}
						openInNewTab
					>
						How to Add Dependents
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/CalmCommunicationTemplate_LaunchingCalmBusinessforDependents.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Dependents Launch Email Template
					</ResourceLink>
				</StyledCollapsibleSection>
				<StyledCollapsibleSection headerText="Calm for Microsoft Teams">
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Microsoft_Teams_Admin_Setup_Instructions.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Microsoft Teams Admin Setup Instructions
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Microsoft_Teams_User_Setup_Instructions.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Microsoft Teams User Setup Instructions
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm-for-Microsoft-Teams-One-Pager.pdf?version=0"
						Icon={DocumentIcon}
						openInNewTab
					>
						Microsoft Teams One-Page Flyer
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm-Communication-Templates-Calm-for-Microsoft-Teams.docx?"
						Icon={DocumentIcon}
						openInNewTab
					>
						Microsoft Teams Announcement Templates
					</ResourceLink>
				</StyledCollapsibleSection>
				<StyledCollapsibleSection headerText="Calm Business for Zoom Meetings">
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Business_Communication_Template_ Calm Business_for_Zoom_Meetings.docx"
						Icon={DocumentIcon}
						openInNewTab
					>
						Launch Email Template
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Business_for_Zoom_Meetings_User_Guide.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						User Guide
					</ResourceLink>
				</StyledCollapsibleSection>
			</Card>
			<Card role="region" aria-labelledby="translated-resources-heading">
				<CellTitle id="translated-resources-heading">Translated resources</CellTitle>
				<StyledCollapsibleSection headerText="2024 12-Month Engagement Guide for Calm Communications">
					<ResourceLink
						href="https://business.calm.com/fr/mise-en-valeur-de-la-communication-de-calm-2024-intl-1/"
						Icon={LinkIcon}
						openInNewTab
					>
						French
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/es/elevating-calm-communications-for-2024-intl-1/"
						Icon={LinkIcon}
						openInNewTab
					>
						Spanish
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/de/elevating-calm-communications-for-2024-intl-1/"
						Icon={LinkIcon}
						openInNewTab
					>
						German
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/ko/elevating-calm-communications-for-2024-intl-2/"
						Icon={LinkIcon}
						openInNewTab
					>
						Korean
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/ja/elevating-calm-communications-for-2024-intl-2/"
						Icon={LinkIcon}
						openInNewTab
					>
						Japanese
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/pt-br/elevating-calm-communications-for-2024-intl-1/"
						Icon={LinkIcon}
						openInNewTab
					>
						Portuguese
					</ResourceLink>
				</StyledCollapsibleSection>
				<StyledCollapsibleSection headerText="Benefit Announcement Flyer">
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1XkpEpnvhVcLeMzUKOKZH6f8i7pH-DCVLV7tnHI1b4B0/edit#slide=id.g16fdcb1f6a5_0_264"
						openInNewTab
					>
						French
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1_DReIeO8PJDDy046NpoinD8zffMEa76xalYKHFu2TfY/edit#slide=id.g16fe294e698_0_264"
						openInNewTab
					>
						Spanish
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1pdppG1wrKUZ2XiTD5dlyQXrDjWnO1yDS9ZDs8Ws7-48/edit#slide=id.g1f17ebf3f1e_0_2"
						openInNewTab
					>
						German
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1prYFd7xzbQrJRKOvN_TUYf9SzAXrSJTLXzbhGVP7C7U/edit#slide=id.g1fddf1d148d_2_89"
						openInNewTab
					>
						Korean
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1Ewk9xNI8izEZFm2ZvLb8m_moP0A3RcF68KOqmYswJoE/edit#slide=id.g1fded11b45e_2_89"
						openInNewTab
					>
						Japanese
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1csbVQjrxclSlL_Y2Gg60vFfyQGOXhgBJ6H6jlkgj6qg/edit#slide=id.g1fded11b49e_1_89"
						openInNewTab
					>
						Portugese
					</ResourceLink>
				</StyledCollapsibleSection>
				<StyledCollapsibleSection headerText="Launch Email Templates">
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/French_Calm_Business_Launch_Template.docx"
						Icon={LinkIcon}
						openInNewTab
					>
						French
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Spanish_Calm_Business_Launch_Template.docx"
						Icon={LinkIcon}
						openInNewTab
					>
						Spanish
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/German_Calm_Business_Launch_Template.docx"
						Icon={LinkIcon}
						openInNewTab
					>
						German
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Korean_Calm_Business_Launch_Template.docx"
						Icon={LinkIcon}
						openInNewTab
					>
						Korean
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Japanese_Calm_Business_Launch_Template.docx"
						Icon={LinkIcon}
						openInNewTab
					>
						Japanese
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Brazilian_Portuguese_Calm_Business_Launch_Template.docx"
						Icon={LinkIcon}
						openInNewTab
					>
						Portuguese
					</ResourceLink>
				</StyledCollapsibleSection>
				<StyledCollapsibleSection headerText="Calm’s Top Recommendations Flyer">
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1NvsQMp8a0NIQnkkPUNOrQeCfjdxwSyCgB8FuG4nutWE/edit#slide=id.g20e6582c32d_2_57"
						openInNewTab
					>
						French
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/16xh4VvX5pGn6WG4m_7ggheut6_jZQ5ELxk-97kxWIHQ/edit#slide=id.g20e649bfba5_1_57"
						openInNewTab
					>
						Spanish
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1CgEN7-glo0NjD2kUuz9cNYAIcDcUIbZJ6KrvD4nWsTw/edit#slide=id.g1b63f4d3fda_2_57"
						openInNewTab
					>
						German
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/14aHW1T0IBxIxNpraKwnhQl5sV-VQ2SC6KQL83O-qujk/edit#slide=id.g20e6535c7dd_1_57"
						openInNewTab
					>
						Korean
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1IhafLiZbeILn6HjJ0GWFB_LLN84PcEcQWJqx5eqTyjY/edit#slide=id.g20e695cc846_1_57"
						openInNewTab
					>
						Japanese
					</ResourceLink>
					<ResourceLink
						Icon={DocumentIcon}
						href="https://docs.google.com/presentation/d/1ywfW5Txwbx6v1fngm2nECk8irBYMe0jAcIU7bzTlGJk/edit#slide=id.g20e6787a227_1_57"
						openInNewTab
					>
						Portuguese
					</ResourceLink>
				</StyledCollapsibleSection>
				<ResourceLink
					href="https://business.calm.com/how-to-invite-dependents-to-utilize-your-calm-business-benefit/"
					Icon={LinkIcon}
					openInNewTab
				>
					Calm for Dependents Launch Instructions
				</ResourceLink>
				<StyledCollapsibleSection headerText="Calm for Dependents Launch Template">
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Communication_Template_Launching_Dependents_fr-FR.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						French
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Communication_Template_Launching_Dependents_es-MX.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Spanish
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Communication_Template_Launching_Dependents_de-DE.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						German
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Communication_Template_Launching_Dependents_ko-KR.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Korean
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Communication_Template_Launching_Dependents_ja-JP.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Japanese
					</ResourceLink>
					<ResourceLink
						href="https://info.calm.com/rs/541-LYF-023/images/Calm_Communication_Template_Launching_Dependents_pt-BR.pdf"
						Icon={DocumentIcon}
						openInNewTab
					>
						Portuguese
					</ResourceLink>
				</StyledCollapsibleSection>
				<ResourceLink
					Icon={DocumentIcon}
					href="https://docs.google.com/presentation/d/1kZTT8XaKt4v0qxbQc-Ly-6exjhi-fECgoR_zt2j_A9M/edit#slide=id.g1dd5a7ee2c1_0_29"
					openInNewTab
				>
					Universal Content Resource
				</ResourceLink>
				<StyledCollapsibleSection headerText="2023 12-Month Engagement Guide for Employee Communications">
					<ResourceLink
						href="https://business.calm.com/fr/guide-dengagement-de-12-mois-intl/"
						Icon={LinkIcon}
						openInNewTab
					>
						French
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/es/12-month-engagement-guide-intl/"
						Icon={LinkIcon}
						openInNewTab
					>
						Spanish
					</ResourceLink>
					<ResourceLink
						href="https://business.calm.com/de/12-month-engagement-guide-intl/"
						Icon={LinkIcon}
						openInNewTab
					>
						German
					</ResourceLink>
				</StyledCollapsibleSection>
			</Card>
			<Card role="region" aria-labelledby="other-resources-heading">
				<CellTitle id="other-resources-heading">Other resources</CellTitle>
				<ResourceLink href="https://business.calm.com/resources/webinars" Icon={LinkIcon} openInNewTab>
					Upcoming HR Webinars
				</ResourceLink>
				<ResourceLink href="https://business.calm.com/resources/events" Icon={LinkIcon} openInNewTab>
					Upcoming HR Events
				</ResourceLink>
				<ResourceLink href="https://business.calm.com/resources/" Icon={LinkIcon} openInNewTab>
					Calm Business Blog and Resources
				</ResourceLink>
				<ResourceLink
					href="https://calm-partner-portal-public-assets-prod.s3.amazonaws.com/Calm+Logos.zip"
					Icon={LinkIcon}
					openInNewTab
				>
					Calm Logos
				</ResourceLink>
				<ResourceLink
					href="https://calm-partner-portal-public-assets-prod.s3.amazonaws.com/Calm+App+Images.zip"
					Icon={LinkIcon}
					openInNewTab
				>
					Calm App Images
				</ResourceLink>
				<ResourceLink
					href="https://www.dropbox.com/scl/fo/qgmxu7ylwrmx5507snzr8/h?rlkey=i3dls3ue5pwrj23jw1lsuypfm&dl=0"
					Icon={LinkIcon}
					openInNewTab
				>
					Calm Social Assets
				</ResourceLink>
				<ResourceLink
					href="https://calm-partner-portal-public-assets-prod.s3.amazonaws.com/Desktop+and+Phone+Backgrounds.zip"
					Icon={LinkIcon}
					openInNewTab
				>
					Desktop and Phone Backgrounds
				</ResourceLink>
				<ResourceLink
					href="https://calm-partner-portal-public-assets-prod.s3.amazonaws.com/Zoom+Virtual+Backgrounds.zip"
					Icon={LinkIcon}
					openInNewTab
				>
					Zoom Virtual Backgrounds
				</ResourceLink>
			</Card>
			<Card role="region" aria-labelledby="faqs-heading">
				<CellTitle id="faqs-heading">FAQs</CellTitle>
				<ResourceLink
					href="https://support.calm.com/hc/en-us/articles/360055261934-How-to-sign-up-with-a-unique-link-and-ID"
					Icon={LinkIcon}
					openInNewTab
				>
					Employee Sign Up FAQ
				</ResourceLink>
			</Card>
		</CellContainer>
	);
};
/* eslint-enable max-len */

export default Resources;
